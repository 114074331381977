import React from "react";

import styles from "./MultifactorAuth.module.css";

export const MultifactorAuth = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.container}>{children}</div>;
};
